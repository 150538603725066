import React, { useEffect, useRef, useState, useCallback } from 'react';

const MazeRenderer = ({ maze, start, ends, onMove }) =>
{
	const [playerPos, setPlayerPos] = useState([...start]);
	const [animatedPos, setAnimatedPos] = useState([...start]);
	const canvasRef = useRef();
	const ifMouseDown = useRef(false);
	const cellSize = 50;

	const drawGoals = useCallback(() =>
	{
		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');
		for(const end of ends)
		{
			const [ex, ey] = end.pos;
			const cx = ex * cellSize + cellSize/2;
			const cy = ey * cellSize + cellSize/2;

			const img = new Image();
			img.src = `https://koalaquill.com/images/icons/captcha/all/${end.img}`;
			console.log(img.src);
			img.onload = () =>
			{
				const aspectRatio = img.width/img.height;
				let drawWidth, drawHeight;

				if (aspectRatio > 1)
				{ 
					drawWidth = cellSize*2/3;
					drawHeight = drawWidth/aspectRatio;
				} else
				{ 
					drawHeight = cellSize*2/3;
					drawWidth = drawHeight*aspectRatio;
				}

				ctx.drawImage(img, cx-(drawWidth/2), cy-(drawHeight/2), drawWidth, drawHeight);
			};
		}
	}, [ends]);

	const drawPlayer = useCallback(() =>
	{
		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');
		const [ax, ay] = animatedPos;

		ctx.font = `${cellSize*2/3}px Arial`;
		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';
		ctx.fillText('\u{1F428}', ax * cellSize + cellSize / 2, ay * cellSize + cellSize / 2);
	}, [animatedPos]);

	const canMove = useCallback((x, y) =>
	{
		const [px, py] = playerPos;
		if (x < 0 || y < 0 || x >= maze.length || y >= maze.length) return false; // Prevent moving outside of the maze
		if (Math.abs(px - x) + Math.abs(py - y) !== 1) return false; // Prevent diagonal movement

		let cell = maze[px][py];
		if (x < px && (cell & 1)) return true;
		if (x > px && (cell & 2)) return true;
		if (y < py && (cell & 4)) return true;
		if (y > py && (cell & 8)) return true;

		return false;
	}, [maze, playerPos]);
	const drawMaze = useCallback(() =>
	{
		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');
		const size = maze.length;

		canvas.width = size * cellSize;
		canvas.height = size * cellSize;

		ctx.clearRect(0, 0, canvas.width, canvas.height);
		ctx.fillStyle = 'black';
		ctx.fillRect(0, 0, canvas.width, canvas.height);

		ctx.strokeStyle = 'white';
		ctx.lineWidth = 2;

		for (let x = 0; x < size; x++)
		{
			for (let y = 0; y < size; y++)
			{
				let cell = maze[x][y];
				let px = x * cellSize;
				let py = y * cellSize;

				ctx.beginPath();
				if (!(cell & 1)) { ctx.moveTo(px, py); ctx.lineTo(px, py + cellSize); }
				if (!(cell & 2)) { ctx.moveTo(px + cellSize, py); ctx.lineTo(px + cellSize, py + cellSize); }
				if (!(cell & 4)) { ctx.moveTo(px, py); ctx.lineTo(px + cellSize, py); }
				if (!(cell & 8)) { ctx.moveTo(px, py + cellSize); ctx.lineTo(px + cellSize, py + cellSize); }
				ctx.stroke();
			}
		}

		drawGoals();
		drawPlayer();
	}, [drawGoals, drawPlayer, maze]);

	const handleMouseMove = useCallback((e, localMouseDown=ifMouseDown.current) =>
	{
		if(!localMouseDown) return;

		const canvas = canvasRef.current;
		const rect = canvas.getBoundingClientRect();
		let mx = Math.floor((e.clientX - rect.left) / cellSize);
		let my = Math.floor((e.clientY - rect.top) / cellSize);

		if (canMove(mx, my))
		{
			setPlayerPos([mx, my]);
			onMove(mx, my);
		}
	}, [canMove, onMove]);

	useEffect(() =>
	{
		drawMaze();
	}, [drawMaze]);

	useEffect(() =>
	{
		let animationFrame;
		const animate = () =>
		{
			setAnimatedPos(([ax, ay]) =>
			{
				const [px, py] = playerPos;
				const speed = 0.3; // Faster interpolation
				
				const nx = ax + (px - ax) * speed;
				const ny = ay + (py - ay) * speed;
				
				if (Math.abs(nx - px) < 0.1 && Math.abs(ny - py) < 0.1) return [px, py]; // Snap to final position
				
				animationFrame = requestAnimationFrame(animate);
				return [nx, ny];
			});
		};
		
		animationFrame = requestAnimationFrame(animate);
		return () => cancelAnimationFrame(animationFrame);
	}, [playerPos]);

	return (
		<canvas
			ref={canvasRef}
			style={{ border: '1px solid white', touchAction: 'none' }}
			onMouseMove={handleMouseMove}
			onTouchMove={(e) => handleMouseMove(e.touches[0],true)}
			onMouseDown={()=>{ifMouseDown.current=true}}
			onMouseUp={()=>{ifMouseDown.current=false}}
		/>
	);
};

export default MazeRenderer;
