import React, { useState, useRef, useEffect, forwardRef } from 'react';
import fetchy from '../../lib/fetchy.js';
import MazeRenderer from './MazeRenderer';
import { promptCaptcha } from '../../lib/notify.js';

const Captcha = forwardRef(({closeToast, setLock}) => {
	const [maze, setMaze] = useState();
	const [path, setPath] = useState([]);
	const [start, setStart] = useState([]);
	const [ends, setEnds] = useState([]);
	const timer = useRef();

	useEffect(() => {
		fetchy.get('/newcaptcha').then(res => {
			console.log(res);
			setMaze(res.data.maze);
			setStart(res.data.start);
			setPath([res.data.start]);
			setEnds(res.data.ends);
		});
	}, []);

	const handleMove = (x, y) =>
	{
		const newPath = [...path, [x, y]];
		setPath(newPath);

		if (timer.current) clearTimeout(timer.current);
		timer.current = setTimeout(() => submitSolution(newPath), 1500);

	}
	const submitSolution = async(path) =>
	{
		if(closeToast) closeToast();
		const response = await fetchy.post('/verifycaptcha', { userSolution: path });
		const { success } = response.data;

		if(success)
		{
			if(setLock) setLock(false);
			else if (window.history.length > 1) window.history.back();
			else window.location.replace('/dashboard');
		} else
		{
			if(setLock) promptCaptcha(setLock, {retry:true});
			else window.location.reload();
		}
	};

	return (
		maze ? <MazeRenderer maze={maze} start={start} ends={ends} onMove={handleMove} /> : <p>Loading...</p>
	);
});

export default Captcha;
