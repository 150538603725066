import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ChevronUp, ChevronDown } from 'lucide-react';
import './index.css';

const TimeWheel = ({ label, max, value, onChange }) =>
{
	const wheelRef = useRef(null);

	const handleWheel = useCallback((event) =>
	{
		event.preventDefault();
		
		const delta = event.deltaY;
		
		onChange((prev) => {
			if (delta < 0) {
				return (prev + 1) % (max + 1);
			} else {
				return (prev - 1 + max + 1) % (max + 1);
			}
		});
	}, [max, onChange]);

	useEffect(() =>
	{
		const wheelElement = wheelRef.current;
		if(!wheelElement) return;

		wheelElement.addEventListener('wheel', handleWheel, { passive: false });
		return () => wheelElement.removeEventListener('wheel', handleWheel);
	}, [handleWheel, onChange, max]);

	const incrementValue = () => onChange((prev) => (prev + 1) % (max + 1));
	const decrementValue = () => onChange((prev) => (prev - 1 + max + 1) % (max + 1));

	const getAdjacentValues = () =>
	{
		const prev = (value - 1 + max + 1) % (max + 1);
		const next = (value + 1) % (max + 1);
		return { prev, next };
	};

	const { prev, next } = getAdjacentValues();

	return (
		<div className="time-unit-selector">
			<button 
				onClick={incrementValue}
				className="wheel-button"
			>
				<ChevronUp className="h-4 w-4" />
			</button>
			
			<div 
				ref={wheelRef}
				className="wheel-control"
			>
				<div className="wheel-control-adjacent wheel-control-adjacent-top">{prev}</div>
				<div className="wheel-control-current">{value}</div>
				<div className="wheel-control-adjacent wheel-control-adjacent-bottom">{next}</div>
				<div className="wheel-control-gradient">
					<div className="wheel-control-gradient-top"></div>
					<div className="wheel-control-gradient-bottom"></div>
				</div>
			</div>
			
			<button 
				onClick={decrementValue}
				className="wheel-button"
			>
				<ChevronDown className="h-4 w-4" />
			</button>
			<label className="time-unit-label">{label}</label>
		</div>
	);
}

const TimeInput = ({ minutes:mins, seconds:secs, onChange }) =>
{
	const [minutes, setMinutes] = useState(mins||0);
	const [seconds, setSeconds] = useState(secs||0);

	useEffect(() =>
	{
		onChange(minutes, seconds);
	}, [minutes, seconds, onChange]);

	return (
		<div className="time-wheel-container">
			<TimeWheel 
				label="Minutes" 
				max={60} 
				value={minutes} 
				onChange={setMinutes} 
			/>
			<div className="time-separator">:</div>
			<TimeWheel 
				label="Seconds" 
				max={59} 
				value={seconds} 
				onChange={setSeconds} 
			/>
		</div>
	);
}

export default TimeInput;
