import React, { useState, useRef, useEffect, Suspense, lazy } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import fetchy from './lib/fetchy.js';
import { Time } from './lib/Time.js';
import { notifyRefer, notifyReferTarget } from './lib/notify.js';

const Interface = lazy(() => import('./components/Interface'));
const LoginSignup = lazy(() => import('./pages/LoginSignup'));
const Captcha = lazy(() => import('./components/Captcha'));
const Home = lazy(() => import('./pages/Home'));

const App = () =>
{
	const [streak, setStreak] = useState();
	const [hearts, setHearts] = useState();
	const [planExpires, setPlanExpires] = useState();
	const [planName, setPlanName] = useState('init_visionary');
	const email = useRef();
	const blockedUsers = useRef([]);

	const [unseen, setUnseen] = useState();
	const [guilds, setGuilds] = useState();
	const [quillmate, setQuillmate] = useState();
	const [levels, setLevels] = useState();
	const [totalWords, setTotalWords] = useState();
	const [lock, setLock] = useState(false);
	const [notify, setNotify] = useState(false);

	const time = useRef(new Time(Date.now()));
	const doneFirstLoad = useRef(false);
	const userId = useRef();
	const welcome = useRef();
	const anonymous = useRef(false);

	useEffect(() =>
	{
		if(doneFirstLoad.current) return;
		doneFirstLoad.current = true;

		(async ()=>
		{
			const response = await fetchy.get('/getuserinfo');
			if(response.data.anonymous)
			{
				setPlanName();
				anonymous.current = true;
				return;
			}

			const {streak, hearts, words, planExpires, planName, now, unseen, guilds, quillmate, levels, resetTrial, lock, notify, ifRefer, ifReferTarget} = response.data;
			userId.current = response.data.userId;
			email.current = response.data.email;
			blockedUsers.current = response.data.blockedUsers;
			setStreak({days:streak, noUpdate:true});
			setHearts(hearts);
			setTotalWords(words);
			setPlanName(planName);
			setLock(lock);
			setNotify(notify);
			if(planExpires) setPlanExpires(new Date(planExpires));
			time.current = new Time(now);

			const welcomeDate = new Date(response.data.last_welcome_time);
			welcome.current = (
				welcomeDate.toLocaleDateString() !== new Date().toLocaleDateString() && 
				(time.current.now() - welcomeDate.getTime()) >=4*60*60*1000
			) ? response.data.welcome : undefined;

			setLevels(levels);
			setUnseen(unseen);
			setGuilds(guilds?.map(e => ({...e, noUpdate:true})));
			if(quillmate) setQuillmate({...quillmate, noUpdate:true});

			console.log({ifRefer,ifReferTarget,s:sessionStorage.notifyRefer});
			if((ifRefer||ifReferTarget) && !sessionStorage.notifyRefer) 
			{
				if(ifRefer) notifyRefer();
				else notifyReferTarget();
				sessionStorage.notifyRefer = 1;
			}
			if(resetTrial && !localStorage.tutorial)
			{
				window.location = '/tutorial';
				localStorage.tutorial = 1;
			}
		})();
	}, [doneFirstLoad, anonymous, email, time, setPlanExpires]);

	const router = createBrowserRouter(
		createRoutesFromElements(<>
			<Route path="/" element={<Suspense><Home {...{ planName, currentGuilds:guilds, setCurrentGuilds:setGuilds, time }} /></Suspense>} />
			<Route path="/login" element={<Suspense><LoginSignup mode="login" /></Suspense>} />
			<Route path="/signup" element={<Suspense><LoginSignup mode="signup" /></Suspense>} />
			<Route path="/resetpassword1" element={<Suspense><LoginSignup mode="resetpassword1" /></Suspense>} />
			<Route path="/resetpassword2" element={<Suspense><LoginSignup mode="resetpassword2" /></Suspense>} />
			<Route path="/captcha" element={<Suspense><Captcha /></Suspense>} />
			<Route path="/:page/:urlId?" element={ <Suspense><Interface {...{ userId, email, streak, setStreak, hearts, setHearts, totalWords, setTotalWords, planName, planExpires, levels, setLevels, unseen, guilds, setGuilds, quillmate, setQuillmate, blockedUsers, welcome, lock, setLock, notify, setNotify, anonymous, time }} /></Suspense> } />
		</>)
	);

	return (<>
		<RouterProvider router={router} />
		<ToastContainer position="top-center" autoClose={10000} />
	</>);
}

export default App;
